<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ใบเสร็จรับเงิน</div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
        ></v-text-field>
      </div>

      <v-spacer></v-spacer>
      <div style="width: 200px;">
        <DatePicker
          label="วันที่"
          :value="filter.start_date"
          @onChange="
            val => {
              filter.start_date = val;
              getData();
            }
          "
          hideDetails
          outlined
          rounded
          dense
        />
      </div>

      <div style="width: 200px;">
        <DatePicker
          label="ถึงวันที่"
          :value="filter.end_date"
          @onChange="
            val => {
              filter.end_date = val;
              getData();
            }
          "
          hideDetails
          outlined
          rounded
          dense
        />
      </div>
      <v-spacer></v-spacer>

      <div style="width: 200px">
        <v-select
          label="สถานะ"
          :items="status"
          item-value="value"
          item-text="text"
          outlined
          rounded
          dense
          hide-details
          v-model="filter.status"
          @change="getData"
        />
      </div>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มรายการ</v-btn
      >
    </v-toolbar>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ item.date_of_issue | moment($formatDate) }}
      </template>
      <template v-slot:[`item.customer_phone_number`]="{ item }">
        <span>{{ phoneNumberFormat(item.customer_phone_number) }}</span>
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.payment.type`]="{ item }">
        {{ item.payment.type == 1 ? 'โอนผ่าน QR Code' : '' }}
        {{ item.payment.type == 2 ? 'บัตรเครดิต' : '' }}
        {{ item.payment.type == 3 ? 'โอนผ่านเลขบัญชี' : '' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship-receipt
          small
          v-model="item.status"
          :id="item._id"
          :statusId="item.status"
          @success="getData()"
          :docTypeId="docTypeId"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="print(item)">
                <v-list-item-title>พิมพ์</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="reset(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';

export default {
  components: {
    DatePicker
  },
  data: () => ({
    bill_id: '',
    _id: '',
    docTypeId: 2,
    loading: true,
    pageCount: 0,
    list: [],
    headers: [
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อร้าน', value: 'customer_name' },
      { text: 'ชื่อออกใบเสร็จ', value: 'name_for_receipt', filterable: false },
      { text: 'เบอร์โทร', value: 'customer_phone_number', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      { text: 'ช่องทาง', value: 'payment.type', filterable: false },
      { text: 'ยอดชำระ', value: 'payment.amount', filterable: false },
      {
        text: 'สถานะ',
        value: 'status',
        sortable: false,
        filterable: false,
        align: 'center'
      },
      { text: 'แก้ไข', value: 'actions', sortable: false, filterable: false }
    ],
    status: [
      { text: 'สถานะ', value: '' },
      { text: 'รอชำระเงิน', value: 0 },
      { text: 'เก็บเงินแล้ว', value: 1 },
      { text: 'ยกเลิก', value: 2 }
    ],
    filter: {
      status: '',
      item_per_page: 20,
      page: 1,
      start_with: '',
      start_date: moment()
        .add(-30, 'days')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    }
  }),
  created() {
    this.getData();
  },
  methods: {
    add() {
      this.$router.push({ name: 'backoffice-receipt-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'backoffice-receipt-update',
        params: { id: item._id }
      });
    },
    async reset(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ bill_id: item._id }, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/backend/bill/delete_bill`, body)
              .then(res => {
                this.getData(true);
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    print(item) {
      this.$router.push({
        name: 'backoffice-receipt-update',
        params: { id: item._id },
        query: { print: true }
      });
    },
    async getData(reload = false) {
      if (reload) {
        this.onChangeitemsPerPage(this.filter.item_per_page);
      }
      this.filter.end_date = moment(this.filter.end_date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/backend/bill/get_bill`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    phoneNumberFormat(val) {
      if (val) {
        return val.substring(0, 3) + '-' + val.substring(3, val.length);
      } else return val;
    }
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
